var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manager-container" },
    [
      _vm.hasSysAdminPermission
        ? _c("div", { staticClass: "left" }, [
            _c(
              "div",
              { staticClass: "title" },
              [
                _c("base-block-title", {
                  attrs: {
                    title: _vm.$t("company"),
                    "sub-title": _vm.companyList.length,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "content" },
              _vm._l(_vm.companyList, function (item) {
                return _c("company-item", {
                  key: item.id,
                  attrs: {
                    company: item,
                    active: item.id === (_vm.currentCompany || {}).id,
                  },
                  nativeOn: {
                    click: function ($event) {
                      _vm.currentCompany = item
                    },
                  },
                })
              }),
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "right" }, [
        _c(
          "div",
          { staticClass: "title-button" },
          [
            _c("base-block-title", {
              attrs: {
                title: _vm.$t("user"),
                "sub-title": _vm.currentCompany.companyName,
                "sub-title2": _vm.roleList.length,
                "sub-title3": _vm.companyStatus,
              },
            }),
            _c(
              "div",
              { staticClass: "button-block" },
              [
                _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "", round: "" },
                        on: { click: _vm.addRowData },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-block" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableLoading,
                    expression: "tableLoading",
                  },
                ],
                attrs: {
                  data: _vm.roleList,
                  "row-key": "id",
                  "default-expand-all": "",
                  height: "100%",
                  "default-sort": { prop: "sort" },
                },
              },
              [
                _c("base-no-data", { attrs: { slot: "empty" }, slot: "empty" }),
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: _vm.$t("serialNum"),
                    align: "center",
                    width: 50,
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "name",
                    align: "center",
                    label: _vm.$t("roleName"),
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "code",
                    align: "center",
                    label: _vm.$t("code"),
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "description",
                    align: "center",
                    label: _vm.$t("desc"),
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "enable",
                    align: "center",
                    label: _vm.$t("enable"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "status",
                              class: scope.row.enable
                                ? "enabled-status"
                                : "disabled-status",
                            },
                            [
                              _c("span"),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.enable
                                      ? `${_vm.$t("enable")}`
                                      : `${_vm.$t("disabled")}`
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "createTime",
                    align: "center",
                    label: _vm.$t("createTime"),
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    fixed: "right",
                    label: "Operate",
                    width: "180",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.hasPermission(_vm.$permissionFunctionCode.EDIT)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    disabled: _vm.defaultRoleCodeList.includes(
                                      scope.row.code
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editRowData(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]
                              )
                            : _vm._e(),
                          _vm.hasPermission(_vm.$permissionFunctionCode.DELETE)
                            ? _c("base-button-delete", {
                                attrs: {
                                  disabled: _vm.defaultRoleCodeList.includes(
                                    scope.row.code
                                  ),
                                },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.deleteRole(
                                      scope.row,
                                      _vm.getRolesByCompany
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.addDialogVisible
        ? _c("base-form-add-or-edit", {
            attrs: {
              visible: _vm.addDialogVisible,
              "url-save": _vm.saveUrl,
              "url-update": _vm.saveUrl,
              fields: _vm.fields,
              labelWidthCn: "100",
              labelWidthEn: "200",
            },
            on: {
              closed: function ($event) {
                _vm.addDialogVisible = false
              },
              "submit-success": _vm.getRolesByCompany,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }